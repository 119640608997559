@if (filters && activeFilters) {
<div class="page-filters">
  <div class="d-flex align-items-baseline">
    <div class="page-filters__label">
      {{ 'radboud.page-filters.label' | translate }}
    </div>

    <div class="page-filters__filters d-flex w-100">
      <div class="page-filters__filters__row row">
        @for (filter of filters; track $index) { @if (!filter.hidden) { @switch (filter.type) { @case (filterType.INPUT)
        {
        <div
          [ngClass]="filter.wrapperStyleClass"
          class="align-vertical hide-validator page-filters__filters__row__filter">
          <cap-input
            #filterSearch
            class="has-icon cap-filter-input"
            styleClass="cap-rounded"
            [ngModel]="filter.isSearch ? search : activeFilters[filter.field]?.value"
            [placeholder]="filter.placeholder | observablePipe | async"
            (onKeyUp)="onTextInputKeyUp($event)"
            (onInput)="setFilter($event.target.value, filter.field, filter.matchMode || 'like', filter.isSearch)">
          </cap-input>

          <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"> </ng-container>

          @if (filter.isSearch && showMinSearchLengthValidation) {
          <span class="search-validation-message">
            {{ 'radboud.search.minimum-length' | translate : { minLength: minSearchLength } }}
          </span>
          }
        </div>
        } @case (filterType.SELECT) { @if (filter?.options | observablePipe | async; as options) { @if
        ((hideFiltersWithoutOptions && options.length) || !hideFiltersWithoutOptions) {
        <div
          class="align-vertical hide-validator page-filters__filters__row__filter"
          [ngClass]="filter.wrapperStyleClass">
          <cap-dropdown
            styleClass="has-icon cap-rounded"
            appendTo="body"
            [virtualScroll]="true"
            [placeholder]="filter?.placeholder | observablePipe | async"
            [ngModel]="activeFilters[filter?.field]?.value"
            [options]="options"
            [showClear]="true"
            [filter]="true"
            (change)="setFilter($event?.value, filter?.field, 'equals')">
          </cap-dropdown>

          <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"> </ng-container>
        </div>
        } } } @case (filterType.MULTISELECT) { @if (filter?.options | observablePipe | async; as options) { @if
        ((hideFiltersWithoutOptions && options.length) || !hideFiltersWithoutOptions) {
        <div
          class="align-vertical hide-validator page-filters__filters__row__filter"
          [ngClass]="filter.wrapperStyleClass">
          <cap-multi-select
            styleClass="has-icon cap-rounded"
            [virtualScroll]="true"
            [maxSelectedLabels]="0"
            [showToggleAll]="filter?.showToggleAll"
            [selectionLimit]="filter?.selectionLimit ? filter.selectionLimit : undefined"
            [ngModel]="activeFilters[filter?.field]?.value"
            [defaultLabel]="filter?.placeholder | observablePipe | async"
            [options]="options"
            (change)="setFilter($event?.value, filter?.field, 'in')">
          </cap-multi-select>

          <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"> </ng-container>
        </div>
        } } } @case (filterType.DATEPICKER) {
        <div
          [ngClass]="filter.wrapperStyleClass"
          class="align-vertical hide-validator page-filters__filters__row__filter">
          <cap-calendar
            styleClass="cap-rounded has-icon w-100"
            [hideOnRangeSelect]="true"
            [showIcon]="false"
            [readonlyInput]="true"
            [selectionMode]="filter?.hasRange ? 'range' : 'single'"
            (select)="
              filter?.hasRange
                ? setDatepickerRangeFilter($event, filter)
                : setFilter($event, filter?.field, 'greaterorequal')
            "
            [(ngModel)]="rangeDates[filter?.field]"
            [ngModel]="calendarValue"
            [placeholder]="filter?.placeholder | observablePipe | async">
          </cap-calendar>

          <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"> </ng-container>
        </div>
        } @case (filterType.CHECKBOX) {
        <div
          [ngClass]="filter.wrapperStyleClass"
          class="align-vertical hide-validator page-filters__filters__row__filter">
          <cap-checkbox
            class="cap-filter-checkbox"
            styleClass="cap-rounded"
            [ngModel]="activeFilters[filter?.field]?.value"
            [label]="filter.placeholder | observablePipe | async"
            (change)="setFilter($event.checked, filter?.field, 'equals')">
          </cap-checkbox>

          <ng-container *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"> </ng-container>
        </div>
        } } } } @if (!buttonsRight()) {
        <ng-container *ngTemplateOutlet="filterButtons"> </ng-container>
        }
      </div>
    </div>

    @if (buttonsRight()) {
    <div class="page-filters-actions">
      <ng-container *ngTemplateOutlet="filterButtons"> </ng-container>
    </div>
    }
  </div>
</div>

<ng-template #filterButtons>
  @if (useApplyButton) {
  <div class="align-vertical page-filters__filters__row__button mr-3">
    <cap-button styleClass="cap-rounded secondary" [label]="applyFiltersLabel | translate" (click)="applyFilters()">
    </cap-button>
  </div>
  } @if (showReset) {
  <div class="align-vertical page-filters__filters__row__button">
    <cap-button styleClass="cap-rounded secondary" [label]="'radboud.button.reset.label' | translate" (click)="reset()">
    </cap-button>
  </div>
  }
</ng-template>

<ng-template #filterIcon let-icon="icon">
  <div class="filter-icon">
    <i [ngClass]="icon"></i>
  </div>
</ng-template>
}
