/* eslint-disable @angular-eslint/no-output-on-prefix */
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CapturumSharedModule, FilterMatchMode, FilterType } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumInputComponent, CapturumInputModule } from '@capturum/ui/input';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { FilterConfig } from '@core/models/filter-config.model';
import { TranslateModule } from '@ngx-translate/core';
import { FilterMetadata } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-filters',
  templateUrl: './page-filters.component.html',
  styleUrls: ['./page-filters.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    CapturumInputModule,
    FormsModule,
    NgTemplateOutlet,
    CapturumDropdownModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumCheckboxModule,
    CapturumButtonModule,
    NgClass,
    AsyncPipe,
    TranslateModule,
    CapturumSharedModule,
  ],
})
export class PageFiltersComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('filterSearch') public filterSearch: CapturumInputComponent;

  @Input() public filters: FilterConfig[];
  @Input() public showReset = true;
  @Input() public useApplyButton = false;
  @Input() public hideFiltersWithoutOptions = false;
  @Input() public minSearchLength: number;
  @Input()
  public applyFiltersLabel = 'radboud.button.apply-filters.label';

  @Input()
  public set activeFilters(value: Record<string, FilterMetadata>) {
    this.search = value?.global?.value || null;

    this._activeFilters = value;
  }

  public get activeFilters(): Record<string, FilterMetadata> {
    return this._activeFilters;
  }

  @Output() public onFilter: EventEmitter<{ field: string; value: any; matchMode: string }> = new EventEmitter<{
    field: string;
    value: any;
    matchMode: string;
  }>();

  @Output() public onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() public onReset: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onApplyFilters: EventEmitter<Record<string, FilterMetadata>> = new EventEmitter<
    Record<string, FilterMetadata>
  >();

  public filterType: typeof FilterType = FilterType;
  public rangeDates: Record<string, Date[]> = {};
  public search: string;
  public calendarValue: Date[] = null;
  public showMinSearchLengthValidation = false;
  public buttonsRight = input<boolean>(false);

  private _activeFilters: Record<string, FilterMetadata>;
  private search$: Subject<string> = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  public ngOnChanges(): void {
    this.formatDate();
  }

  public ngOnInit(): void {
    this.search$.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((search) => {
      return this.onSearch.emit(search);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onTextInputKeyUp(event: KeyboardEvent): void {
    if (this.useApplyButton && event.key === 'Enter') {
      this.applyFilters();
    }
  }

  /**
   * Set the filter
   *
   * @param value: any
   * @param field: string
   * @param matchMode: string
   * @param isSearch: boolean
   * @return void
   */
  public setFilter(value: any, field: string, matchMode: string, isSearch?: boolean): void {
    this.showMinSearchLengthValidation = false;

    if (isSearch && this.minSearchLength) {
      if (value.length && value.length < this.minSearchLength) {
        this.showMinSearchLengthValidation = true;

        return;
      }
    }

    if (this.useApplyButton) {
      const filterField = isSearch ? 'global' : field;

      if (!this.isFilterBlank(value)) {
        this.activeFilters[filterField] = { value: value, matchMode: matchMode };
      } else if (this.activeFilters[filterField]) {
        delete this.activeFilters[filterField];
      }

      return;
    }

    if (isSearch) {
      this.search$.next(value);
    } else {
      this.onFilter.emit({ field, value, matchMode });
    }
  }

  /**
   * Set input switch filter
   *
   * @param checked: boolean
   * @param field: string
   * @return void
   */
  public setInputSwitchFilter(checked: boolean, field: string): void {
    this.setFilter('null', field, checked ? FilterMatchMode.NOT_EQUALS : FilterMatchMode.EQUALS, false);
  }

  /**
   * Set a range datepicker filter
   *
   * @return void
   * @param value
   * @param filterConfig
   */
  public setDatepickerRangeFilter(value: Date, filterConfig: FilterConfig): void {
    if (
      this.rangeDates[filterConfig?.field].some((field) => {
        return !field;
      })
    ) {
      if (this.useApplyButton) {
        this.rangeDates[filterConfig?.field][1] = this.rangeDates[filterConfig?.field][0];
      } else {
        return;
      }
    }

    this.setFilter(this.rangeDates[filterConfig?.field], filterConfig?.field, FilterMatchMode.BETWEEN);
  }

  public applyFilters(): void {
    this.onApplyFilters.emit(this.activeFilters);
  }

  /**
   * Reset all filter states
   *
   * @return void
   */
  public reset(): void {
    this.calendarValue = [];
    this.search = null;

    if (this.filterSearch) {
      this.filterSearch.value = '';
    }

    if (this.filters && this.filters.length) {
      this.filters.forEach((item) => {
        if (this.activeFilters && this.activeFilters[item.field]) {
          delete this.activeFilters[item.field];
        }
      });
    }

    this.onReset.emit();
  }

  private formatDate(): void {
    if (this.filters && this.filters.length) {
      this.filters.forEach((item) => {
        if (this.activeFilters && this.activeFilters[item.field] && item.type === this.filterType.DATEPICKER) {
          this.calendarValue = [];
          this.activeFilters[item.field].value.forEach((date) => {
            this.calendarValue.push(new Date(date));
          });
        } else {
          this.calendarValue = null;
        }
      });
    }
  }

  private isFilterBlank(filter: any): boolean {
    if (filter !== null && filter !== undefined) {
      if ((typeof filter === 'string' && filter.trim().length == 0) || (Array.isArray(filter) && filter.length == 0)) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }
}
