import { QuillConfig } from 'ngx-quill';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { editorSettings } from '@src/app/core/consts/editor.const';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { HtmlContentCardComponent } from '@shared/components/html-content-card/html-content-card.component';

@Component({
  template: `
    @if (props.disabled) {
      <app-html-content-card [htmlContent]="formControl.value || ''" styleClass="html-card__content ql-editor p-0">
      </app-html-content-card>
    }
    @if (!props.disabled) {
      <cap-editor
        [formlyAttributes]="field"
        [formControl]="formControl"
        [required]="props.required || false"
        [class.ng-dirty]="showError"
        [config]="props.config || editorConfig">
      </cap-editor>
    }
  `,
  standalone: true,
  imports: [CapturumEditorModule, FormlyModule, FormsModule, ReactiveFormsModule, HtmlContentCardComponent],
})
export class EditorFormlyTypeComponent extends FieldType {
  public editorConfig: QuillConfig = editorSettings;
}
