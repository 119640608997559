import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { CustomLoginService } from '@src/app/core/services/custom-login.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { SurfConextResponse } from '@src/app/core/models/base.model';

@Component({
  templateUrl: './surfconext-login-callback.component.html',
  styleUrls: ['./surfconext-login-callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SurfconextLoginCallbackComponent implements OnInit {
  constructor(
    private loginService: CustomLoginService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    // Base64 response data containing JWT and user object
    const responseBase64: string = this.route.snapshot.queryParamMap.get('data');

    if (responseBase64 && responseBase64 !== '') {
      const responseJson: string = atob(responseBase64);
      const response: SurfConextResponse = JSON.parse(responseJson);

      if (response.status === 'ok') {
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.setItem('token', response.token);
        this.authService.setAuthenticationState(true);

        if (response.user.is_public_authenticated) {
          this.router.navigate(['/']);
        } else {
          this.loginService.onLoginSuccess(response);
        }
      } else {
        this.toastService.error(this.translateService.instant('toast.error.title'), response.error);

        this.router.navigate([AppRoutes.authLogin]);
      }
    }
  }
}
