import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { CustomUserService } from '@core/services/custom-user.service';
import { User } from '@features/manage-user/models/user.model';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserCompleteGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly userService: CustomUserService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfUserIsComplete(state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfUserIsComplete(state);
  }

  private checkIfUserIsComplete(state: RouterStateSnapshot): boolean {
    const user = this.authService.getUser() as unknown as User;

    if (!user || state.url === `/${AppRoutes.profile}` || user.is_public_authenticated) {
      return true;
    }

    if (this.userService.isUserIncomplete(user)) {
      this.toastService.error(
        this.translateService.instant('radboud.user.incomplete.label'),
        this.translateService.instant('radboud.user.incomplete.message'),
      );
      this.router.navigate([AppRoutes.profile]);
    }

    return true;
  }
}
